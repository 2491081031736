import { getField, updateField } from 'vuex-map-fields'
import requests from '../requests'
import { askAirene } from '@/common/services'
import { ROOMS_MESSAGE } from '@/assets/variables/endpoints'

export const state = () => ({
  firstOpenAskAirene: true,
  conversationSuggestion: [],
  conversationAbly: [],
  conversationHistory: {},
})

export const getters = {
  getField,
}

export const actions = {
  postSuggestionList({ commit }, params) {
    return new Promise((resolve, reject) => {
      askAirene
        .postSuggestion(params.roomId)
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getSuggestionList({ commit }, params) {
    return new Promise((resolve, reject) => {
      askAirene
        .getSuggestion(params.roomId)
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getConversation({ commit, state }, params) {
    const url = `${ROOMS_MESSAGE}/${params.room}`
    const parameter = {
      limit: params.limit,
      offset: params.offset,
      cursor: params.cursor,
    }
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(url, parameter, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            resolve(res.data)
          },
          (err) => {
            reject(err)
          }
        )
    })
  },
  getSourceUrl({ commit }, params) {
    return new Promise((resolve, reject) => {
      askAirene
        .getSourceUrl(params.sourceId)
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getSourceByName({ commit }, params) {
    return new Promise((resolve, reject) => {
      askAirene
        .getSourceByName(params.sourceName, params.sourceType)
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getAblyHistory({ commit }, params) {
    return new Promise((resolve, reject) => {
      askAirene
        .getAblyConversationHistory(params)
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getTourFlag({ commit }, params) {
    return new Promise((resolve, reject) => {
      askAirene
        .getTourFlag(params)
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
}

export const mutations = {
  updateField,
  SET_ROOM_SUGGESTIONS(state, payload) {
    const currentRoom = {
      roomId: payload.roomId,
      suggestion: payload.suggestion,
    }
    const suggestionExistId = state.conversationSuggestion.findIndex(
      (item) => item.roomId === payload.roomId
    )
    if (suggestionExistId !== -1) {
      state.conversationSuggestion[suggestionExistId] = currentRoom
    } else {
      state.conversationSuggestion.push(currentRoom)
    }
  },
  UPDATE_WELCOME_ASK_AIRENE(state, payload) {
    state.firstOpenAskAirene = payload
  },
  SEND_MESSAGE_ASK_AIRENE(state, payload) {
    const convIndex = state.conversationAbly.findIndex((item) => {
      return item.roomId === payload.roomId
    })
    if (convIndex === -1) {
      state.conversationAbly.push({
        roomId: payload.roomId,
        conversations: [payload.msg],
      })
    } else {
      state.conversationAbly[convIndex].conversations.push(payload.msg)
    }
  },
  SET_ROOM_HISTORY(state, payload) {
    state.conversationHistory[payload.roomId] = payload.history
  },
  SET_ABLY_HISTORY(state, payload) {
    state.conversationAbly = [
      {
        roomId: payload.roomId,
        conversations: payload.history,
      },
    ]
  },
  SET_TOUR_FLAG(state, payload) {
    state.firstOpenAskAirene = payload
  },
}
